/**
 * https://www.meilisearch.com/docs/reference/api/overview
 */
'use server';

import { store } from '../../../../store';
import { Locale } from '../../../i18n';

import { getSearchClient } from './client';
import { Cms_SearchResponse, Cms_SearchResult } from './search.types';

const indexes = ['sitemap_node', 'partner', 'team_member', 'blog_post', 'page'];

export const searchContents = async (
  q: string,
  tenantUid: string, // Tenant_Uid,
  locale: Locale
): Promise<Cms_SearchResponse | null> => {
  const client = getSearchClient();
  const { config } = store.getPageData();

  if (!client) {
    return null; // getApiError('Search client not initialized');
  }

  const response = await client.multiSearch({
    queries: indexes.map(index => ({
      indexUid: config?.fullTextSearch?.meilisearchIndexPrefix
        ? `${config?.fullTextSearch?.meilisearchIndexPrefix}_${index}`
        : index,
      q
    }))
  });

  const results: Cms_SearchResult[] = [];
  let totalHits = 0;

  response.results.forEach(result => {
    let hits = result.hits.filter(h => h.tenants?.includes(tenantUid) && h.locale === locale);
    totalHits += hits.length;
    const prefix = store.getPageData().config?.fullTextSearch?.meilisearchIndexPrefix;

    switch (result.indexUid) {
      case `${prefix}_sitemap_node`:
        hits = hits.filter(h => h.type === 'internal' && h.path);
        break;
      case `${prefix}_page`:
        hits = hits.filter(h => h.publishedAt && h.sitemapNodes && h.sitemapNodes.length > 0);
        break;

      case `${prefix}_patner`:
        hits = hits.filter(h => h.publishedAt);
        break;

      case `${prefix}_team_member`:
        //  hits = hits.filter(h => h.publishedAt);
        break;

      case `${prefix}_blog_post`:
        hits = hits.filter(h => h.publishedAt);
        break;

      default:
        break;
    }

    if (hits.length > 0) {
      results.push({ ...result, hits });
    }
  });

  return {
    results,
    totalHits
  };
};
