'use client';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Center, Flex, Grid, GridItem, VStack } from '@pt-frontends/styled-system/jsx';
import { cmsContentContactForm } from '@pt-frontends/styled-system-web/recipes';
import { useSearchParams } from 'next/navigation';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { useIsMobile, useLanguage, usePageProps } from '@lib/hooks';
import { SmtpConfig, sendContactRequest } from '@lib/server-actions';
import { Cms_ContentContactForm } from '@lib/services/cms';
import { useTranslation } from '@lib/services/i18n/client';
import { Tenant_Uid } from '@lib/services/tenant';
import { getTitleTag } from '@lib/utils';

import { Button } from '@ui/button';
import { Checkbox } from '@ui/checkbox';
import { CmsEditorText } from '@ui/cmsEditorText';
import { Dialog, DialogBody, DialogContent, DialogFooter } from '@ui/dialog';
import { Form, FormControl, FormField, FormInputText, FormItem, FormMessage } from '@ui/form';
import { Icon } from '@ui/icon';
import { Img } from '@ui/img';
import { CountrySelect } from '@ui/select';
import { Text } from '@ui/text';

interface ContactFormProps {
  data: Cms_ContentContactForm;
  formId: string;
  //  i18n: ContactFormTranslations;
  hasCaptcha?: boolean;
  smtpConfig?: SmtpConfig;
  userEmail?: string;
  userCompany?: string;
  userName?: string;
}

type FetchStatus = 'idle' | 'loading' | 'error' | 'success';

const ContactForm: React.FC<ContactFormProps> = ({
  data,
  formId,
  hasCaptcha,
  smtpConfig,
  userEmail,
  userCompany,
  userName
}) => {
  const {
    containerInner,
    teaser,
    title,
    formWrapper,
    imgBox,
    infoIcon,
    infoText,
    resIcon,
    resMsg,
    buttons,
    gridItem,
    gridItemAddr,
    terms,
    termsDialogContent,
    termsCheckboxText,
    termsCheckboxError
  } = cmsContentContactForm();
  const {
    params: { tenant }
  } = usePageProps();
  const { t } = useTranslation();
  const pageProps = usePageProps();
  const [status, setStatus] = useState<FetchStatus>('idle');
  const [googleRecaptchaToken, setGoogleRecaptchaToken] = useState<string | null>();
  const catpchaRef = useRef<ReCAPTCHA>(null);
  const isMobile = useIsMobile();
  const [siteKey, setSiteKey] = useState<string | undefined>(undefined);
  const searchParams = useSearchParams();
  const [isTermsOpen, setTermsOpen] = useState(false);
  const isTermsEnabled = data.terms && data.terms.checkboxLabel && data.terms.content;
  const contactRef = useMemo(() => searchParams.get('contactRef'), [searchParams]);
  const locale = useLanguage();

  const formSchema = useMemo(() => {
    const schema: any = {
      email: z
        .string({
          required_error: t('uiWeb.cmsContents.contact.field.fieldRquired', {
            name: t('uiWeb.cmsContents.contact.field.email')
          })
        })
        .email({ message: t('uiWeb.cmsContents.contact.formItem.emailInvalid') }),
      name: z
        .string({
          required_error: t('uiWeb.cmsContents.contact.field.fieldRquired', {
            name: t('uiWeb.cmsContents.contact.field.name')
          })
        })
        .max(100),
      topic: z
        .string({
          required_error: t('uiWeb.cmsContents.contact.field.fieldRquired', {
            name: t('uiWeb.cmsContents.contact.field.topic')
          })
        })
        .max(100),
      message: z
        .string({
          required_error: t('uiWeb.cmsContents.contact.field.fieldRquired', {
            name: t('uiWeb.cmsContents.contact.field.message')
          })
        })
        .min(1)
        .max(2000),
      country: z.string().max(2).optional()
    };

    if (isTermsEnabled) {
      schema.terms = z.literal(true, {
        errorMap: () => ({ message: t('uiWeb.cmsContents.contact.field.termsMustBeAccepted') })
      });
    }

    if (data.formFields?.includes('company')) {
      schema.company = z
        .string({
          required_error: t('uiWeb.cmsContents.contact.field.fieldRquired', {
            name: t('uiWeb.cmsContents.contact.field.company')
          })
        })
        .max(100);
    }
    if (data.formFields?.includes('industry')) {
      schema.industry = z.string().max(100).optional();
    }
    if (data.formFields?.includes('phone')) {
      schema.phone = z.string().max(100).optional();
    }

    return z.object(schema);
  }, [data.formFields, isTermsEnabled, t]);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: userEmail,
      company: userCompany,
      name: userName
    }
  });

  const isBtnDisalbed = useMemo(() => {
    if (status === 'loading') return true;

    if (hasCaptcha === false) return false;

    return googleRecaptchaToken === undefined;
  }, [googleRecaptchaToken, hasCaptcha, status]);

  const onSubmit = useCallback(
    async (values: z.infer<typeof formSchema>) => {
      if (!googleRecaptchaToken && hasCaptcha !== false) return;

      setStatus('loading');

      const isSuccess = await sendContactRequest({
        pageProps,
        formData: values as any,
        url: window.location.href,
        tenantUid: tenant as Tenant_Uid,
        googleRecaptchaToken,
        context: data.context,
        referer: contactRef,
        emailSubject: data.emailSubject,
        smtpConfigOverwrite: smtpConfig
      });

      if (!isSuccess) {
        setStatus('error');

        return;
      }

      setStatus('success');
    },
    [
      contactRef,
      data.context,
      data.emailSubject,
      googleRecaptchaToken,
      hasCaptcha,
      pageProps,
      smtpConfig,
      tenant
    ]
  );

  const handleCaptcha = (token: string | null) => {
    setGoogleRecaptchaToken(token);
  };

  const handleReset = () => {
    setStatus('idle');
    form.reset();
  };

  useEffect(() => {
    setStatus('idle');
  }, []);

  useEffect(() => {
    if (!isMobile || status !== 'success') return;

    const el = document.getElementById(formId);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', inline: 'start' });
    }
  }, [formId, isMobile, status]);

  useEffect(() => {
    setSiteKey(window._env_?.captchaSiteKey);
  }, []);

  return (
    <>
      <Grid gap={8} columns={{ base: 1, lg: 2 }} className={containerInner}>
        <GridItem id={formId} className={gridItem}>
          {status !== 'success' && status !== 'error' && (
            <>
              <Text className={teaser} variant="teaser">
                {data.teaser}
              </Text>
              <Text className={title} variant="h2" as={getTitleTag(data.titleHtmlTag, 'h2')}>
                {data.title}
              </Text>
              <CmsEditorText variant="blockLg" data={data.text} />

              <div className={formWrapper}>
                <Form {...form}>
                  <form onSubmit={form.handleSubmit(onSubmit)}>
                    <Flex gap={4} flexDir="column">
                      <Grid gap={4} columns={{ base: 1, md: 2 }}>
                        <FormField
                          name="email"
                          control={form.control}
                          render={({ field }) => (
                            <FormInputText
                              isMui
                              label={t('uiWeb.cmsContents.contact.field.email')}
                              required
                              field={field as any}
                              {...field}
                            />
                          )}
                        />
                        <FormField
                          name="name"
                          control={form.control}
                          render={({ field }) => (
                            <FormInputText
                              field={field as any}
                              isMui
                              required
                              label={t('uiWeb.cmsContents.contact.field.name')}
                              {...field}
                            />
                          )}
                        />

                        {data.formFields?.includes('phone') && (
                          <FormField
                            name="phone"
                            control={form.control}
                            render={({ field }) => (
                              <FormInputText
                                field={field as any}
                                isMui
                                label={t('uiWeb.cmsContents.contact.field.phone')}
                              />
                            )}
                          />
                        )}

                        <FormField
                          name="topic"
                          control={form.control}
                          render={({ field }) => (
                            <FormInputText
                              field={field as any}
                              isMui
                              required
                              label={t('uiWeb.cmsContents.contact.field.topic')}
                            />
                          )}
                        />

                        {data.formFields?.includes('company') && (
                          <FormField
                            name="company"
                            control={form.control}
                            render={({ field }) => (
                              <FormInputText
                                field={field as any}
                                isMui
                                required
                                label={t('uiWeb.cmsContents.contact.field.company')}
                              />
                            )}
                          />
                        )}

                        {data.formFields?.includes('industry') && (
                          <FormField
                            name="industry"
                            control={form.control}
                            render={({ field }) => (
                              <FormInputText
                                field={field as any}
                                isMui
                                label={t('uiWeb.cmsContents.contact.field.industry')}
                              />
                            )}
                          />
                        )}

                        <FormField
                          name="country"
                          control={form.control}
                          render={({ field }) => (
                            <CountrySelect
                              label={t('uiWeb.cmsContents.contact.field.country')}
                              {...field}
                            />
                          )}
                        />
                      </Grid>

                      <FormField
                        name="message"
                        control={form.control}
                        render={({ field }) => (
                          <FormInputText
                            label={t('uiWeb.cmsContents.contact.field.message')}
                            required
                            multiLine
                            isMui
                            rows={4}
                            field={field as any}
                            {...field}
                          />
                        )}
                      />

                      {isTermsEnabled && (
                        <FormItem className={terms}>
                          <FormField
                            control={form.control}
                            name="terms"
                            render={({ field }) => {
                              return (
                                <FormItem>
                                  <FormControl>
                                    <div>
                                      <Flex gap={1} alignItems="flex-start">
                                        <Checkbox
                                          checked={field.value}
                                          onCheckedChange={checked => {
                                            field.onChange(checked);
                                          }}
                                        />
                                        <button
                                          onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setTermsOpen(true);
                                          }}
                                        >
                                          <CmsEditorText
                                            variant="blockSm"
                                            data={data.terms?.checkboxLabel}
                                            className={termsCheckboxText}
                                          />
                                        </button>
                                      </Flex>
                                      <FormMessage className={termsCheckboxError} />
                                    </div>
                                  </FormControl>
                                </FormItem>
                              );
                            }}
                          />
                        </FormItem>
                      )}

                      {siteKey && hasCaptcha !== false && (
                        <ReCAPTCHA
                          sitekey={siteKey}
                          onChange={handleCaptcha}
                          ref={catpchaRef}
                          lang={locale}
                        />
                      )}

                      <div className={buttons}>
                        <Button
                          size="default"
                          variant="primary"
                          icon="arrow-right"
                          type="submit"
                          loading={status === 'loading'}
                          disabled={isBtnDisalbed}
                        >
                          {t('uiWeb.cmsContents.contact.btn.label')}
                        </Button>
                      </div>
                    </Flex>
                  </form>
                </Form>
              </div>
            </>
          )}

          {status === 'success' && (
            <Center h="full">
              <VStack>
                <Icon i="check-circle" className={resIcon} />
                <div className={title}>
                  <Text variant="block" className={resMsg}>
                    {t('uiWeb.cmsContents.contact.success')}
                  </Text>
                  <Text variant="block" className={resMsg}>
                    {t('uiWeb.cmsContents.contact.successTwo')}
                  </Text>
                </div>
                <Button size="default" variant="secondary" onClick={handleReset}>
                  {t('uiWeb.cmsContents.contact.succBtn.label')}
                </Button>
              </VStack>
            </Center>
          )}

          {status === 'error' && (
            <Center h="full">
              <VStack>
                <Icon i="flag-exclamation" className={resIcon} />
                <div className={title}>
                  <Text variant="block" className={resMsg}>
                    {t('uiWeb.cmsContents.contact.error')}
                  </Text>
                  <Text variant="block" className={resMsg}>
                    {t('uiWeb.cmsContents.contact.errorTwo')}
                  </Text>
                </div>
                <Button size="default" variant="secondary" onClick={handleReset}>
                  {t('uiWeb.cmsContents.contact.succBtn.label')}
                </Button>
              </VStack>
            </Center>
          )}
        </GridItem>

        {data.address && (
          <GridItem className={gridItemAddr}>
            <div className={imgBox}>
              <Img fill data={data.address.image} alt={data.title} />
            </div>
            <Flex flexDir="column" gap={6} p={8}>
              <Flex gap={8}>
                <Icon i="pin-location" className={infoIcon} />
                <CmsEditorText data={data.address.address} variant="blockLg" className={infoText} />
              </Flex>
              <Flex gap={8}>
                <Icon i="phone" className={infoIcon} />
                <Text variant="block" className={infoText}>
                  <a href={`tel:${data.address.phone}`}>{data.address.phone}</a>
                </Text>
              </Flex>
              <Flex gap={8}>
                <Icon i="envelope" className={infoIcon} />
                <Text variant="block" className={infoText}>
                  <a href={`mailto:${data.address.email}`}>{data.address.email}</a>
                </Text>
              </Flex>
            </Flex>
          </GridItem>
        )}
      </Grid>
      {isTermsEnabled && (
        <Dialog open={isTermsOpen} onOpenChange={o => setTermsOpen(o)}>
          <DialogContent className={termsDialogContent}>
            <DialogBody>
              <CmsEditorText data={data.terms?.content} variant="blockSm" />
            </DialogBody>
            <DialogFooter>
              <Button onClick={() => setTermsOpen(false)}>Close</Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export { ContactForm };
export type { ContactFormProps };
