'use client';

import React, { PropsWithChildren, useEffect, useRef } from 'react';

import { cx } from '@pt-frontends/styled-system/css';
import { HStack } from '@pt-frontends/styled-system/jsx';
import { imageViewer } from '@pt-frontends/styled-system/recipes';
import { useWindowSize } from 'react-use';
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import { useIsMobile, useIsMounted } from '@lib/hooks';
import { Cms_SingleMedia } from '@lib/services/cms';

import { Dialog, DialogContent, DialogHeader, DialogTrigger } from '@ui/dialog';
import { Icon } from '@ui/icon';
import { Img } from '@ui/img';

interface ImageViewerProps extends PropsWithChildren {
  image?: Cms_SingleMedia;
  triggerCl?: string;
}

type ZoomHandler = (direction: '-' | '+' | 'reset') => void;

const ImageViewer = React.forwardRef<React.ElementRef<typeof DialogContent>, ImageViewerProps>(
  ({ image, children, triggerCl }, ref) => {
    const [isOpen, setOpen] = React.useState(false);
    const transformRef = useRef<ReactZoomPanPinchRef>(null);
    const imageRef = useRef<HTMLImageElement | HTMLElement>(null);
    const isMounted = useIsMounted();
    const { height } = useWindowSize();
    const isMobile = useIsMobile();
    const cl = imageViewer();

    const handleZoom: ZoomHandler = (direction: '-' | '+' | 'reset') => {
      if (transformRef.current && imageRef.current) {
        if (direction === '+') {
          transformRef.current.zoomIn(0.3, 200);
        }

        if (direction === '-') {
          transformRef.current.zoomOut(0.3, 200);
        }

        if (direction === 'reset') {
          transformRef.current.zoomToElement(imageRef.current, undefined, 0);
          transformRef.current.centerView();
        }
      }
    };

    /**
     * remove styles added by dialog to body, pointer events....
     */
    useEffect(() => {
      const h = document.body.querySelector('header');

      if (isOpen) {
        if (h) {
          h.style.position = 'fixed';
          h.style.width = '100%';
        }

        setTimeout(() => {
          document.body.removeAttribute('style');
        }, 500);
      } else {
        h?.removeAttribute('style');
      }
    }, [isOpen]);

    useEffect(() => {
      if (isMounted && transformRef.current) {
        setTimeout(() => {
          handleZoom('reset');
        }, 200);
      }
    }, [isMounted]);

    return (
      <Dialog open={isOpen} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <button
            className={cx(cl.trigger, triggerCl)}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(true);
            }}
          >
            <Icon i="search" className={cl.icon} />
          </button>
        </DialogTrigger>
        <DialogContent ref={ref} overlayCl={cl.overlay} className={cl.content}>
          <DialogHeader className={cl.header}>
            <HStack gap={3}>
              <button className={cl.button} onClick={() => handleZoom('+')}>
                <Icon i="zoom-in" className={cl.iconBig} />
              </button>
              <button className={cl.button} onClick={() => handleZoom('-')}>
                <Icon i="zoom-out" className={cl.iconBig} />
              </button>
              <button className={cl.button} onClick={() => handleZoom('reset')}>
                <Icon i="expand" className={cl.iconBig} />
              </button>
            </HStack>
          </DialogHeader>
          <div className={cl.body} style={{ height: isMobile ? height - 70 : height - 90 }}>
            <TransformWrapper
              onInit={() => {
                handleZoom('reset');
              }}
              ref={transformRef}
              wheel={{ step: 0.08 }}
              doubleClick={{ step: 0.3 }}
              minScale={0.4}
            >
              <TransformComponent
                wrapperStyle={{ height: isMobile ? height - 70 : height - 90, width: '100%' }}
              >
                <div ref={imageRef as any}>{children}</div>

                {image && (
                  <Img
                    ref={imageRef as any}
                    width={image.data?.width}
                    height={image.data?.height}
                    data={image}
                  />
                )}
              </TransformComponent>
            </TransformWrapper>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
);
ImageViewer.displayName = 'ImageViewer';

export { ImageViewer };
