'use client';

import React, { useCallback, useEffect, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { css } from '@pt-frontends/styled-system/css';
import { Flex } from '@pt-frontends/styled-system/jsx';
import { cookieBanner } from '@pt-frontends/styled-system-web/recipes';
import dayjs from 'dayjs';
import { isEqual, keys, omit } from 'lodash';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { z } from 'zod';

import { COOKIE_GA_CONSENT_KEY } from '@lib/constants';
import { useIsMounted } from '@lib/hooks';
import { useTranslation } from '@lib/services/i18n/client';
import { CookiePolicy } from '@lib/types';
import type { FetchStatus } from '@lib/types';
import { addTrailingSlash, base64ToObject, objectToBase64 } from '@lib/utils';

import { Button } from '@ui/button';
import { Checkbox } from '@ui/checkbox';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@ui/dialog';
import { Form, FormField } from '@ui/form';
import { Icon } from '@ui/icon';
import { Text } from '@ui/text';

// replace later with appropriate values
const CHECKBOX_NECESSARY = 'nescessary';
const CHECKBOX_PERFORMANCE = 'performance';
const CHECKBOX_MARKETING = 'marketing';

interface CookieValues {
  date: string;
  nescessary: boolean;
  performance: boolean;
  marketing: boolean;
}

interface CookieBannerProps {
  privacyLink?: string;
  isSettings?: boolean;
}

const CookieBanner: React.FC<CookieBannerProps> = ({ isSettings, privacyLink }) => {
  const [isSettingOpen, setSettingOpen] = useState(false);
  const [status, setStatus] = useState<FetchStatus>('idle');
  const [isManageCookies, setIsManageCookies] = useState<boolean>(false);
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [cookies, setCookieValue, removeCookieValue] = useCookies();
  const cookieValue = cookies[COOKIE_GA_CONSENT_KEY];
  const [cookieValues, setCookieValues] = useState<CookiePolicy | null>(null);
  const {
    dialogContent,
    dialogBody,
    dialogBodySrollArea,
    line,
    cookieInfo,
    infoText,
    checkTitle,
    checkText,
    cookieIcon,
    dialogFooter,
    footerCookieBtn,
    cookieInfoDate
  } = cookieBanner({ isManagedCookies: isManageCookies || false });
  const pathname = usePathname();
  const isPrivacyPage = pathname === addTrailingSlash(privacyLink);

  const formSchema = isManageCookies
    ? z
        .object({
          [CHECKBOX_NECESSARY]: z.literal(true),
          [CHECKBOX_PERFORMANCE]: z.boolean(),
          [CHECKBOX_MARKETING]: z.boolean()
        })
        .refine(d => d[CHECKBOX_NECESSARY] || d[CHECKBOX_PERFORMANCE || d[CHECKBOX_MARKETING]], {
          path: [CHECKBOX_NECESSARY, CHECKBOX_PERFORMANCE, CHECKBOX_MARKETING]
        })
    : z.object({});

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: isSettings
      ? {
          [CHECKBOX_NECESSARY]: true,
          [CHECKBOX_PERFORMANCE]: cookieValues?.performance === false ? false : true,
          [CHECKBOX_MARKETING]: cookieValues?.marketing === false ? false : true
        }
      : {
          [CHECKBOX_NECESSARY]: true,
          [CHECKBOX_PERFORMANCE]: true,
          [CHECKBOX_MARKETING]: true
        }
  });

  const { watch } = form;
  const watchAllFields = watch();

  const setCookie = useCallback(
    (value: string) => {
      const current = new Date();
      const nextYear = new Date();
      nextYear.setFullYear(current.getFullYear() + 1);
      setCookieValue(COOKIE_GA_CONSENT_KEY, value, {
        expires: nextYear
      });
    },
    [setCookieValue]
  );

  const handleCookieSettings = () => {
    setIsManageCookies(true);
    setSettingOpen(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const removeAnalyticsCookies = () => {
    if (typeof window.gtag === 'function') {
      window.gtag('consent', 'default', {
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        analytics_storage: 'denied'
      });
    }

    const hx = window.location.hostname.split('.');
    const domain = `.${hx[hx.length - 2]}.${hx[hx.length - 1]}`;

    keys(cookies).forEach(key => {
      if (key.startsWith('_')) {
        // eslint-disable-next-line no-console
        console.log('Removing ' + key);
        removeCookieValue(key, { domain, path: '/' });
        //  Cookies.remove(key, { domain, path: '/' });
      }
    });
  };

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setStatus('loading');

    const cookieConf: CookiePolicy = {
      date: new Date().toISOString(),
      nescessary: true
    };

    if (!isManageCookies) {
      cookieConf.performance = true;
      cookieConf.marketing = true;
    } else {
      cookieConf.performance = (values as CookieValues).performance;
      cookieConf.marketing = (values as CookieValues).marketing;
    }

    if (!cookieConf.performance && !cookieConf.marketing) {
      removeAnalyticsCookies();
    }

    const cookieStr = objectToBase64(cookieConf);
    setCookie(cookieStr);
    setStatus('success');

    if (isSettings) {
      setSettingOpen(false);
    }

    window.location.reload();
  };

  useEffect(() => {
    if (!cookieValues) return;
    form.reset({
      marketing: cookieValues.marketing,
      performance: cookieValues.performance,
      nescessary: true
    });
  }, [cookieValues, form]);

  useEffect(() => {
    if (cookieValues) return;

    let value: CookiePolicy | null = null;

    if (cookieValue) {
      try {
        const v = base64ToObject<CookiePolicy>(cookieValue as string);
        if (
          v &&
          v.marketing !== undefined &&
          v.performance !== undefined &&
          v.nescessary !== undefined
        ) {
          value = v;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('Error parsing cookie, will remove...', e);
      }
    }

    if (!value && cookieValue) {
      // eslint-disable-next-line no-console
      console.warn('Error parsing cookie, will remove...');
      removeCookieValue(COOKIE_GA_CONSENT_KEY);
      removeAnalyticsCookies();
      window.location.reload();

      return;
    }

    if (!value) {
      return;
    }

    setCookieValues(value);
  }, [cookieValue, cookieValues, removeAnalyticsCookies, removeCookieValue]);

  /**
   * cleanup old cookie
   */
  useEffect(() => {
    if (cookies.DSG) {
      removeCookieValue('DSG');
    }
  }, [cookies?.DSG, removeCookieValue]);

  const formFields = (
    <>
      <FormField
        name={CHECKBOX_NECESSARY}
        control={form.control}
        render={({ field }) => (
          <>
            <Checkbox
              {...field}
              checked
              value={CHECKBOX_NECESSARY}
              className={css({ pointerEvents: 'none' })}
            >
              <Text variant="teaser" className={checkTitle}>
                {t('uiWeb.cookies.banner.checkbox.necessary.title')}
              </Text>
            </Checkbox>
            <Text variant="teaser" className={checkText}>
              {t('uiWeb.cookies.banner.checkbox.necessary.text')}
            </Text>
          </>
        )}
      />
      <div className={line} />
      <FormField
        name={CHECKBOX_PERFORMANCE}
        control={form.control}
        render={({ field }) => (
          <>
            <Checkbox
              {...field}
              onCheckedChange={checked => field.onChange(checked)}
              checked={field.value}
              value={CHECKBOX_PERFORMANCE}
            >
              <Text variant="teaser" className={checkTitle}>
                {t('uiWeb.cookies.banner.checkbox.statistics.title')}
              </Text>
            </Checkbox>
            <Text variant="teaser" className={checkText}>
              {t('uiWeb.cookies.banner.checkbox.statistics.text')}
            </Text>
          </>
        )}
      />
      <div className={line} />
      <FormField
        name={CHECKBOX_MARKETING}
        control={form.control}
        render={({ field }) => (
          <>
            <Checkbox
              {...field}
              onCheckedChange={checked => field.onChange(checked)}
              checked={field.value}
              value={CHECKBOX_MARKETING}
            >
              <Text variant="teaser" className={checkTitle}>
                {t('uiWeb.cookies.banner.checkbox.marketing.title')}
              </Text>
            </Checkbox>
            <Text variant="teaser" className={checkText}>
              {t('uiWeb.cookies.banner.checkbox.marketing.text')}
            </Text>
          </>
        )}
      />
    </>
  );

  /**
   * temp cleanup of the old value
   */
  useEffect(() => {
    if (cookies.DSG) {
      removeCookieValue('DSG', { path: '/' });
    }
    if (cookies.KVANT_DSG) {
      removeCookieValue('KVANT_DSG', { path: '/' });
    }
  }, [cookies, removeCookieValue]);

  if (!isMounted) {
    return null;
  }

  const privacyPage = privacyLink ? (
    <Link href={privacyLink} className={css({ color: 'primary' })} />
  ) : (
    <span />
  );

  if (isSettings) {
    // eslint-disable-next-line no-alert
    return (
      <>
        <Dialog open={isSettingOpen}>
          <DialogContent
            className={dialogContent}
            isCloseBtnShown={false}
            onPointerDownOutside={e => e.preventDefault()}
            onOpenAutoFocus={e => e.preventDefault()}
          >
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <DialogHeader>
                  <DialogTitle>{t('uiWeb.cookies.banner.editTitle')}</DialogTitle>
                  <Icon i="cookie" className={cookieIcon} />
                </DialogHeader>
                <DialogBody scrollAreaCl={dialogBodySrollArea} className={dialogBody}>
                  <div>
                    <Text as="span" variant="block-sm" className={infoText}>
                      <Trans
                        i18nKey="uiWeb.cookies.banner.info.text"
                        components={{ privacyPage }}
                      />
                    </Text>
                    <Flex flexDir="column">
                      <div className={line} />
                      {cookieValues?.date && (
                        <Text variant="teaser" className={cookieInfo}>
                          <Trans
                            i18nKey="uiWeb.cookies.banner.dateInfo"
                            components={{
                              date: (
                                <span className={cookieInfoDate}>
                                  {dayjs(cookieValues?.date).format('DD. MMMM YYYY, HH:mm')}
                                </span>
                              )
                            }}
                          />
                        </Text>
                      )}
                      {formFields}
                      <div className={line} />
                    </Flex>
                  </div>
                </DialogBody>
                <div className={dialogFooter}>
                  <DialogFooter className={css({ mt: 0 })}>
                    {isManageCookies ? (
                      <Button
                        type="button"
                        variant="secondary"
                        size="small"
                        onClick={() => {
                          setIsManageCookies(false);
                          setSettingOpen(false);
                        }}
                      >
                        {t('uiWeb.cookies.banner.close')}
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        variant="secondary"
                        size="small"
                        onClick={() => setIsManageCookies(true)}
                      >
                        {t('uiWeb.cookies.banner.btnManageCookies')}
                      </Button>
                    )}
                    {isManageCookies && (
                      <Button
                        type="submit"
                        variant="primary"
                        icon="arrow-right"
                        size="small"
                        loading={status === 'loading'}
                        disabled={
                          cookieValues
                            ? isEqual(form.getValues(), omit(cookieValues, 'date'))
                            : false
                        }
                      >
                        {t('uiWeb.cookies.banner.confirmChoices')}
                      </Button>
                    )}
                  </DialogFooter>
                </div>
              </form>
            </Form>
          </DialogContent>
        </Dialog>
        <button className={footerCookieBtn} onClick={handleCookieSettings}>
          {t('uiWeb.cookies.footer.label')}
        </button>
      </>
    );
  }

  return (
    <Dialog open={cookieValues === null && !isPrivacyPage}>
      <DialogContent
        className={dialogContent}
        isCloseBtnShown={false}
        onPointerDownOutside={e => e.preventDefault()}
        onOpenAutoFocus={e => e.preventDefault()}
      >
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <DialogHeader>
              <DialogTitle>{t('uiWeb.cookies.banner.title')}</DialogTitle>
              <Icon i="cookie" className={cookieIcon} />
            </DialogHeader>
            <DialogBody
              scrollAreaCl={css({ maxH: 'calc(100dvh - 220px)', mb: 4 })}
              className={css({ maxH: 'calc(100dvh - 200px)' })}
            >
              <div>
                {!isManageCookies && (
                  <>
                    <Text as="span" variant="block-sm" className={infoText}>
                      <Trans i18nKey="uiWeb.cookies.banner.text" components={{ privacyPage }} />
                    </Text>
                  </>
                )}
                {isManageCookies && (
                  <>
                    <Text variant="block-sm" as="span" className={infoText}>
                      <Trans
                        i18nKey="uiWeb.cookies.banner.info.text"
                        components={{ privacyPage }}
                      />
                    </Text>
                    <Flex flexDir="column">
                      <div className={line} />
                      {formFields}
                      <div className={line} />
                    </Flex>
                  </>
                )}
              </div>
            </DialogBody>
            <div className={dialogFooter}>
              <DialogFooter className={css({ mt: 0 })}>
                <Button
                  type="button"
                  variant="secondary"
                  size="small"
                  onClick={() => setIsManageCookies(!isManageCookies)}
                >
                  {isManageCookies
                    ? t('uiWeb.cookies.banner.back')
                    : t('uiWeb.cookies.banner.btnManageCookies')}
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  icon="arrow-right"
                  size="small"
                  loading={status === 'loading'}
                  disabled={
                    status === 'loading' ||
                    (isManageCookies === true &&
                      (!(CHECKBOX_NECESSARY in watchAllFields) ||
                        !watchAllFields[CHECKBOX_NECESSARY]) &&
                      (!(CHECKBOX_PERFORMANCE in watchAllFields) ||
                        !watchAllFields[CHECKBOX_PERFORMANCE]))
                  }
                >
                  {isManageCookies === true
                    ? t('uiWeb.cookies.banner.confirmChoices')
                    : t('uiWeb.cookies.banner.btnAllowAll')}
                </Button>
              </DialogFooter>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export type { CookieBannerProps };
export { CookieBanner };
